var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mission
    ? _c(
        "div",
        { staticClass: "asset-wrap" },
        [
          _vm.isVideoAsset
            ? _c("VideoAsset", {
                staticClass: "asset-wrap__video-asset",
                attrs: { src: _vm.mission.youtube, controls: _vm.isHost },
              })
            : _vm._e(),
          _vm.isYoutubeAsset
            ? _c("YoutubeAsset", {
                staticClass: "asset-wrap__video-asset",
                attrs: { videoCode: _vm.mission.youtube, controls: _vm.isHost },
              })
            : _vm.asset === _vm.AssetTypes.Photo
            ? _c(
                "div",
                { staticClass: "asset-wrap__image-asset" },
                [
                  _vm.mission.behavior === _vm.MissionType.BuzzIn &&
                  _vm.mission.zoomable
                    ? _c("ZoomableImage", { attrs: { src: _vm.missionPhoto } })
                    : _c("img", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { src: _vm.missionPhoto },
                        on: {
                          click: function ($event) {
                            return _vm.zoomMissionAsset(_vm.missionPhoto)
                          },
                        },
                      }),
                  _vm.priceIsRightAnswer
                    ? _c(
                        "div",
                        { staticClass: "asset-wrap__price-is-right-answer" },
                        [_vm._v(" " + _vm._s(_vm.priceIsRightAnswer)), _c("br")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "asset-mapper__controls" },
                    [
                      _vm.mission.autoDownloadPhotoAsset
                        ? _c(
                            "RtbButton",
                            {
                              attrs: {
                                size: "sm",
                                outline: "",
                                variant: "icon",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadjs(_vm.missionPhoto)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "download", size: "small" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.mission.zoomable
                        ? _c(
                            "RtbButton",
                            {
                              attrs: {
                                size: "sm",
                                outline: "",
                                variant: "icon",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.zoomMissionAsset(_vm.missionPhoto)
                                },
                              },
                            },
                            [
                              _c("svg-icon", {
                                attrs: { name: "zoom-in", size: "small" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isMediaBtnVisible
                        ? _c(
                            "v-tooltip",
                            {
                              key: "photo",
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "ToolbarBtn",
                                          _vm._g(
                                            {
                                              staticStyle: {
                                                padding: "0",
                                                margin: "0 5px",
                                                background: "#1f2125",
                                                height: "24px",
                                              },
                                              attrs: {
                                                size: "small",
                                                active:
                                                  _vm.$_MediaMixin_value ===
                                                  "photo",
                                              },
                                              on: {
                                                click: _vm.$_MediaMixin_toggle,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c("svg-icon", {
                                              attrs: { name: "picture" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3782954561
                              ),
                            },
                            [_c("span", [_vm._v("Show/Hide Mission Photo")])]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm.isAudioAsset
            ? _c("AudioAsset", {
                staticClass: "asset-wrap__audio-asset",
                attrs: { audio: _vm.mission.audio, controls: "" },
              })
            : _vm.asset === _vm.AssetTypes.Drawing
            ? _c(
                "MissionContainer",
                {
                  key: "drawing-info-" + _vm.missionID,
                  staticClass: "asset-wrap__mission-container",
                  attrs: { mode: "explain" },
                },
                [
                  _c("DrawingTextInput", {
                    attrs: { mode: _vm.mode, mission: _vm.mission },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }