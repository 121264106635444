<template>
  <div class="asset-wrap" v-if="mission">
    <VideoAsset
      v-if="isVideoAsset"
      :src="mission.youtube"
      :controls="isHost"
      class="asset-wrap__video-asset"
    />

    <YoutubeAsset
      v-if="isYoutubeAsset"
      :videoCode="mission.youtube"
      :controls="isHost"
      class="asset-wrap__video-asset"
    />

    <div class="asset-wrap__image-asset" v-else-if="asset === AssetTypes.Photo">
      <ZoomableImage
        v-if="mission.behavior === MissionType.BuzzIn && mission.zoomable"
        :src="missionPhoto"
      />
      <img
        v-else
        :src="missionPhoto"
        style="cursor: pointer"
        @click="zoomMissionAsset(missionPhoto)"
      />
      <div class="asset-wrap__price-is-right-answer" v-if="priceIsRightAnswer">
        {{ priceIsRightAnswer }}<br />
      </div>

      <div class="asset-mapper__controls">
        <RtbButton
          size="sm"
          outline
          variant="icon"
          v-if="mission.autoDownloadPhotoAsset"
          @click="downloadjs(missionPhoto)"
        >
          <svg-icon name="download" size="small" />
        </RtbButton>
        <RtbButton
          v-if="!mission.zoomable"
          size="sm"
          outline
          variant="icon"
          @click="zoomMissionAsset(missionPhoto)"
        >
          <svg-icon name="zoom-in" size="small" />
        </RtbButton>
        <v-tooltip v-if="isMediaBtnVisible" key="photo" bottom>
          <template v-slot:activator="{ on }">
            <ToolbarBtn
              v-on="on"
              size="small"
              style="
                padding: 0;
                margin: 0 5px;
                background: #1f2125;
                height: 24px;
              "
              :active="$_MediaMixin_value === 'photo'"
              @click="$_MediaMixin_toggle"
            >
              <svg-icon name="picture" />
            </ToolbarBtn>
          </template>
          <span>Show/Hide Mission Photo</span>
        </v-tooltip>
      </div>
    </div>

    <AudioAsset
      v-else-if="isAudioAsset"
      :audio="mission.audio"
      controls
      class="asset-wrap__audio-asset"
    />
    <MissionContainer
      v-else-if="asset === AssetTypes.Drawing"
      mode="explain"
      :key="`drawing-info-${missionID}`"
      class="asset-wrap__mission-container"
    >
      <DrawingTextInput :mode="mode" :mission="mission" />
    </MissionContainer>
  </div>
</template>

<script>
import Vue from "vue"
import { mapState, mapGetters, mapActions } from "vuex"
import downloadjs from "downloadjs"
import isVideo from "is-video"

import Mission from "@shared/enums/Mission"
import MediaMixin from "@/mixins/MediaMixin"

import { RtbButton } from "@/components/ui"

export const AssetTypes = {
  Drawing: "drawing",
  Video: "video",
  Audio: "audio",
  Photo: "photo",
  Ourvideo: "ourvideo",
  None: "none"
}

export default Vue.extend({
  name: "AssetMapper",
  components: {
    RtbButton,
    ZoomableImage: () => import("@/components/ZoomableImage/ZoomableImage.vue"),
    YoutubeAsset: () => import("@/components/Assets/YoutubeAsset"),
    ToolbarBtn: () => import("@/components/GroupTeams/Common/ToolbarBtn"),
    DrawingTextInput: () =>
      import("@/components/GroupTeams/Common/Games/DrawingTextInput"),
    MissionContainer: () =>
      import("@/components/GroupTeams/Common/Games/MissionContainer"),
    VideoAsset: () => import("@/components/Assets/VideoAsset"),
    AudioAsset: () => import("@/components/Assets/AudioAsset")
  },
  mixins: [MediaMixin],
  setup() {
    return { MissionType: Mission }
  },
  props: {
    priceIsRightAnswer: String,
    mode: {
      type: String,
      default: null
    },
    drawing: Boolean,
    showToggleAsset: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      asset: null,
      AssetTypes
    }
  },
  computed: {
    ...mapState(["gameID", "orgID"]),
    ...mapGetters("auth", ["user", "isHost", "isModerator"]),
    ...mapGetters("livechat", ["roomID"]),
    ...mapGetters(["game"]),
    ...mapGetters({
      missionID: "currentMission",
      mission: "getCurrentMission",
      gameStatus: "gameStatus"
    }),
    isMediaBtnVisible() {
      return this.showToggleAsset && (this.isHost || this.isModerator)
    },
    isBuzzIn() {
      return this.mission?.behavior === Mission.BuzzIn
    },
    isPriceIsRight() {
      return this.mission?.behavior === Mission.PriceIsRight
    },
    mediaToShow() {
      if (this.roomID && this.mission?.photo) return AssetTypes.Photo
      if (this.roomID && this.mission?.audio) return AssetTypes.Audio
      if (this.mission?.behavior === Mission.YouTube) return null
      if (this.gameStatus?.media === AssetTypes.None) return null
      if (this.drawing) return AssetTypes.Drawing
      if (isVideo(this.mission?.youtube)) return AssetTypes.Ourvideo

      return this.gameStatus?.media ?? null
    },
    missionStateHash() {
      return `${this.mode}-${this.missionID}`
    },
    isVideoAsset() {
      return this.mission.youtube && this.asset === AssetTypes.Ourvideo
    },
    isYoutubeAsset() {
      return this.mission.youtube && this.asset === AssetTypes.Video
    },
    isAudioAsset() {
      return this.mission.audio && this.asset === AssetTypes.Audio
    },
    missionPhoto() {
      return this.mission.photo ? decodeURI(this.mission.photo) : null
    }
  },
  watch: {
    missionStateHash() {
      this.$bus.$emit("zoomMissionAssetPhoto", {
        visible: false,
        photo: null
      })
    },
    mediaToShow(value) {
      this.asset = value
    }
  },
  mounted() {
    // keep the current asset type locally
    this.asset = this.mediaToShow

    if (
      (this.isAudioAsset || this.isVideoAsset) &&
      this.$store.getters["auth/volume"] < 0.5
    )
      this.updateUserVolume({ userID: this.user?.id, volume: 0.5 })
  },
  beforeDestroy() {
    this.$bus.$emit("zoomMissionAssetPhoto", {
      visible: false,
      photo: null
    })
  },
  methods: {
    ...mapActions("soundeffect", ["updateUserVolume"]),
    zoomMissionAsset(photo) {
      this.$bus.$emit("zoomMissionAssetPhoto", {
        visible: true,
        photo
      })
    },
    downloadjs
  }
})
</script>
<style lang="scss">
.asset-wrap {
  position: absolute;
  width: 100%;
  height: 100%;

  &__mission-container {
    background: initial !important;
    border: initial !important;
    border-radius: initial !important;
    text-align: center;
  }

  &__video-asset,
  &__audio-asset {
    width: 100%;
    height: 100%;
  }

  &__answer-asset {
    position: absolute;
    background-color: #000;
    font-weight: bold;
    color: #fff;
    z-index: 5;
    width: 100%;
    text-align: center;
    opacity: 0.7;
    top: 0px;
    left: 0px;
    padding: 8px;
  }

  &__image-asset {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    & > div {
      max-width: 100%;
      max-height: 100%;
    }
    canvas {
      max-width: 100%;
      max-height: 100%;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__price-is-right-answer {
    position: absolute;
    color: #f8f9fb;
    font-weight: 400;
    border-radius: 4px;
    padding: 3px 8px;
    height: 35px;
    line-height: 24px;
    background-color: rgba(22, 17, 26, 0.8);
    font-size: 25px;
    font-weight: bold;
  }

  &::after {
    content: "";
    width: 0px;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .zoom-icon {
    top: 0px;
    position: absolute;
    color: white;
    cursor: pointer;
    height: 22px;
    width: 22px;
    right: 0px;
    z-index: 10;
  }
}

.asset-mapper__controls {
  position: absolute;
  right: 8px;
  bottom: 8px;
  display: flex;
  align-items: center;
}
</style>
